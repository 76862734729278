import moment from 'moment-timezone';

///////////////////// CHART OPTIONS AND CUSTOMIZATION /////////////////////////
/**
 * Function for adding the half day annotations in the correct position based off of the 
 * datasets charted.
 * @param {Object} chartData Access to the chart data object
 * @param {Object} annotationLines The actual annotation lines that have been added to the chart object
 */
export const UpdateHalfDayAnnotations = function (chartData, annotationLines) {
  annotationLines.forEach(function (annotationLine, index) {
    if (annotationLine.id.indexOf("HalfDay") !== -1) {
      const firstMidnight = new moment(chartData.datasets[0].startDate).startOf("day");
      annotationLine.value = moment(firstMidnight).add(index * 12, "h").valueOf();
      const dayIndex = index / 2 + 1;
      if (index % 2) {
        annotationLine.borderWidth = 2;
      } else {
        annotationLine.label.content = "Day " + dayIndex;
      }
    }
  });
};

/**
 * Adds annotations for showing half days on chart
 * @param {Number} numDays the number of days to create annotations for
 * @returns {Array} array of objects of annotations
 */
export function AddDayAnnotations(numDays) {
  var annotationLines = [];
  var i = 0;
  for (i = 0; i < 2*numDays; i++) {
    annotationLines.push(
      {
        drawTime: "beforeDatasetsDraw",
        id: "HalfDay" + (i + 1),
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: "",
        borderColor: "black",
        borderWidth: 5,
        label: {
          // Background color of label, default below
          backgroundColor: 'rgba(0,0,0,0)',
          // Font family of text, inherits from global
          fontFamily: "sans-serif",
          // Font size of text, inherits from global
          fontSize: 16,
          // Font style of text, default below
          fontStyle: "bold",
          // Font color of text, default below
          fontColor: "#000",
          content: "",
          enabled: true,
          // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
          position: "top",
          xAdjust: -50
        }
      });
  }
  return annotationLines;
}