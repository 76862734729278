import React from 'react';
import moment from 'moment-timezone';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import CalendarTheme from '../../styling/themes/CalendarTheme';
import { DateRangePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

ThemedStyleSheet.registerInterface(aphroditeInterface);

export class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      focusedInput: null,
    };

    this.valuesDirty = false;

    ThemedStyleSheet.registerTheme(CalendarTheme);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      startDate: nextProps.startDate,
      endDate: nextProps.endDate
    });
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }
  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  onDatesChange({ startDate, endDate }) {
    // check if either date has actually changed
    if (startDate && !startDate.isSame(this.state.startDate)) {
      this.valuesDirty = true;
    }
    if (endDate && !endDate.isSame(this.state.endDate)) {
      this.valuesDirty = true;
    }

    this.setState({ startDate, endDate }, this.afterStateChange);

    this.setState({ startDate, endDate });
  }

  // handle onChange here to ensure state values are current, as setState is asynchronous
  afterStateChange = () => {
    let { startDate, endDate, focusedInput } = this.state;

    // fire onChange if either date has changed and calendar has closed (whether automatic or click away)
    if (!focusedInput && this.valuesDirty) {
      this.valuesDirty = false;
      this.props.onDatesChange && this.props.onDatesChange({ startDate, endDate });
    }
  }

  handleFocusChange = focusedInput => {
    this.setState({ focusedInput: focusedInput }, this.afterStateChange);
  };

  isOutsideRange(date) {
    return moment(date) < moment(this.props.minDate) || moment(date) > moment(this.props.maxDate);
  }

  updateDate(dateType) {
    this.setState({ dateType });
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <DateRangePicker
        customInputIcon={<FontAwesomeIcon
          icon="calendar-alt"
          style={{ fontSize: '1.75em' }}
        />}
        minimumNights={this.props.minimumNights || 0}
        displayFormat={"DD/MM/YYYY"}
        isOutsideRange={this.isOutsideRange}
        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={this.handleFocusChange.bind(this)} // PropTypes.func.isRequired,
      />
    );
  }
}
