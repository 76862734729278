
/// INITIAL STATE
const initialState = {
  // app info
  app: {
    version: `${process.env.REACT_APP_VERSION} alpha`,
    environment: "development"
  },
  // settings
  settings: {
    keepDateCurrent: true
  }
};

// REDUCER HELPERS


/// REDUCER - General site stuff
export const reducer = (state, action) => {
  state = state || initialState;
  return state;
};