import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactResizeDetector from 'react-resize-detector';
import FlexView from 'react-flexview';
import _ from 'lodash';
import { Chart as OPGChart, Options as ChartOptions, DatasetProperties } from './index';

/**
 * Takes in typical forecast/historical datasets and truncates them according to the 
 * numDays parameter. If startDate is passed it is used to filter out data before it
 * @param {Object} tableData An array of dataset objects
 * @param {number} numDays Number of days from startDate or earliest data point in array
 * @param {Date} startDate Optional - the start date of the filtering
 * @returns {object} the filtered datasets
 */
export const FilterDatasets = (tableData, numDays, startDate) => {
  if (tableData.length < 0) {
    return;
  }

  const startDay = startDate !== undefined ? startDate :
    moment(_.min(_.map(tableData, forecast => forecast.data.length > 0 ? forecast.data[0].t :
      "9999-99-99T99:99:99Z")));

  var chartData = _.map(tableData, forecast => {
    const lastDate = moment(startDay).hours(0).add(numDays, "d");
    let data = _.filter(forecast.data, p => moment(startDay) <= moment(p.t) && moment(p.t) <= lastDate)
      .map(p => { return { ...p, y: p.value }; });
    forecast.startDate = startDay.format();
    return Object.assign({}, forecast, { data });
  });

  return chartData;
};

export class ChartComponent extends PureComponent {
  state = {
    isLoading: true,
    chart: new OPGChart(this.getChartOptions())
  }

  componentDidMount() {
    const canvas = this.refs.canvas;
    this.state.chart.SetChartCanvas(canvas);
    this.state.chart.Initialize();
    this.SetupAnnotations(this.state.chart, this.props.options);

    if (this.props.options.yStepSizeFunction)
      this.state.chart.AddCustomVerticalStepSizeFunction(this.props.options.yStepSizeFunction);
    if (this.props.options.xStepSizeFunction) {
      this.state.chart.AddCustomHorizontalStepSizeFunction(this.props.options.xStepSizeFunction);
    }
    this.UpdateDataForChart(this.props.data);
  }

  
  componentWillReceiveProps(nextProps) {
    // if data has loaded - then let's update the chart
    if (nextProps.dataLoaded) {
      this.RemoveAnnotations(this.state.chart, this.props.options);
      this.SetupAnnotations(this.state.chart, nextProps.options);
      this.UpdateDataForChart(nextProps.data);
      this.state.chart.UpdateSize();
    } else {
      // keep showing loading status
      this.setState({ isLoading: true});
    }
  }

  componentWillUnmount() {
    if (this.state.chart !== null) {
      this.state.chart.Destroy();
    }
  }

  SetupAnnotations(chart, options) {
    // Annotations
    if (options.annotations) {
      options.annotations.forEach(l => {
        chart.AddAnnotation(l);
      });
    }
    if (options.annotationUpdateFunctions) {
      options.annotationUpdateFunctions.forEach(f => {
        chart.AddAnnotationUpdateFunction(f);
      });
    }
  }

  RemoveAnnotations(chart, options) {
    // Remove Annotations
    if (options.annotations) {
      options.annotations.forEach(l => {
        chart.RemoveAnnotation(l.id);
      });
    }
  }

  UpdateDataForChart(data) {
    if (!data) {
      return;
    }
    if (data.length < 1 || (data.length === 1 && _.isEmpty(data[0]))) {
      return;
    }

    var chartData = { datasets: data };
    var refDate;

    chartData.datasets =
      chartData.datasets.filter(d => d.data.length !== 0).map(function (dataset, index) {
        if (dataset.data.length === 0) {
          return;
        }
        if (dataset.properties === null) {
          dataset.properties = {};
        }
        dataset = { ...dataset, ...new DatasetProperties() };
        dataset.borderWidth = 2;
        dataset.pointBorderWidth = 0;
        dataset.pointHoverRadius = 0;
        dataset.pointRadius = 0;
        dataset.pointHitRadius = 0;

        var colour = dataset.colour;
        dataset.lineColour = colour;
        dataset.backgroundColour = colour;

        if (index === 0) {
          refDate = moment(dataset.data[0].t).subtract(1, "h");
        } else {
          refDate = moment.min(refDate, moment(dataset.data[0].t).subtract(1, "h"));
        }

        return dataset;
      });
    if (chartData.datasets.length === 0) {
      return;
    }
    this.state.chart.SetReferenceDate(refDate);
    this.state.chart.SetChartData(chartData, true);

    this.setState({ isLoading: false });

  }

  getChartOptions() {
    const passedOptions = this.props.options;
    // Init OPG Chart options, and customize any options in addition to that
    const options = new ChartOptions();
    options.responsive = true;
    options.title.text = passedOptions.title;
    options.legend.toggle = passedOptions.toggleVisibility;
    // y axes options
    options.scales.yAxes[0].scaleLabel.labelString = passedOptions.yLabel;
    options.scales.yAxes[0].ticks.dynamicStepSize = true;   // not needed for now
    options.scales.yAxes[0].marginTop = passedOptions.yMarginTop;
    options.scales.yAxes[0].marginBottom = passedOptions.yMarginBottom;
    options.scales.yAxes[0].stepFactor = passedOptions.yStepFactor;
    options.scales.yAxes[0].gridLines.majorEnabled = passedOptions.yMajorEnabled;
    options.scales.yAxes[0].invalidValue = undefined;
    // x axes options
    options.scales.xAxes[0].gridLines.majorEnabled = passedOptions.xMajorEnabled;
    options.scales.xAxes[0].scaleLabel.labelString = passedOptions.xLabel;
    options.scales.xAxes[0].ticks.minorToMajor = passedOptions.xMinorToMajor;
    options.scales.xAxes[0].numUnitsToMaxTime = passedOptions.xNumUnitsToMaxTime;
    options.scales.xAxes[0].numUnitsToMinTime = passedOptions.xNumUnitsToMinTime;
    options.scales.xAxes[0].ticks.referenceHourEnabled = passedOptions.xReferenceHourEnabled;
    options.scales.xAxes[0].ticks.autoSkip = false;
    // allows for time rescaling step 
    options.scales.xAxes[0].ticks.dynamicStepSize = true;   // not needed for now
    options.scales.xAxes[0].ticks.maxRotation = passedOptions.xTicksMaxRotation;
    options.scales.xAxes[0].time = passedOptions.xTime;

    // disable shift and redraw
    options.shift.enabled = passedOptions.shift;
    options.draw.enabled = passedOptions.draw;
    // zoom/pan options
    options.zoom.enabled = passedOptions.zoom;
    options.pan.enabled = passedOptions.pan;
    options.pan.mode = passedOptions.mode;
    
    return options;
  }



  onResize = () => {
    if (this.state.chart)
      this.state.chart.UpdateSize();
  }

  render() {
    const vClass = this.state.isLoading ? 'hidden' : 'visible';
    return (
      <div>
        <FlexView vAlignContent='center' hAlignContent='center' className='forecastChart'>
          {this.state.isLoading ? <FlexView rows='true' vAlignContent='center'> <FlexView column ><FontAwesomeIcon size="5x" icon="spinner" spin /></FlexView><FlexView column marginLeft={10}><h1>Loading...</h1></FlexView> </FlexView> : "" }
          {this.state ? (<canvas ref="canvas" className={'forecastChart ' + vClass} />) : ""}
          <ReactResizeDetector handleWidth onResize={this.onResize} refreshMode='debounce' refreshRate={100} />
        </FlexView>
      </div>
    );
  }
}
