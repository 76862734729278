import { AuthenticationContext, adalFetch, withAdalLogin, } from 'react-adal';

export const adalConfig = {
  tenant: '962f21cf-93ea-449f-99bf-402e2b2987b2',
  clientId: 'dd53fc2b-8205-4366-a796-7c4c08b69b3e',
  redirectUri: window.location.href,
  endpoints: {
    api: 'dd53fc2b-8205-4366-a796-7c4c08b69b3e',
  },
  extraQueryParameter: 'nux=1',
  cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const authApiFetch = (url, options) =>
  adalApiFetch(fetch, url, options);

export const authApiFetchJson = async (url, options) => {
  options = {
    ...options,
    body: options !== undefined && options.body !== undefined
      ? JSON.stringify(options.body) : undefined,
  };
  const response = await authApiFetch(url, options);
  return response.json();
};

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

export const getUserFullName = () => {
  const profile = authContext.getCachedUser().profile;

  var firstName = profile.given_name;
  var lastName = profile.family_name;

  return { first: titleCase(firstName), last: titleCase(lastName) };
}

const titleCase = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}