import {
  SET_FORECASTS_START_DATE,
  REQUEST_HOEP_FORECASTS,
  RECEIVE_HOEP_FORECASTS,
  SUBMIT_HOEP_FORECAST,
  RECEIVE_ADDED_FORECAST,
  REQUEST_SUBMITTED_HOEP_FORECAST,
  RECEIEVE_SUBMITTED_HOEP_FORECAST
} from '../actions/Forecasts';
import moment from 'moment-timezone';
import _ from 'lodash';
import produce from 'immer';

/// INITIAL STATE
const initialState = {
  // the start date
  startDate: moment().startOf("day").add(-1, "d").hour(1),
  // end date
  endDate: moment().startOf("day").hour(24),
  // FORECASTS state:
  // - hoep forecast (more could come)
  // - loading status of each
  data: {
    hoep: {
      all: {},
      tMinus1: {},
      submitted: {}
    },
    loading: false
  },
  // last time server was contacted for data
  lastUpdatedAt: moment(),
  // SETTINGS state:
  // - empty for now
  settings: {
  }
};

// REDUCER HELPERS
/**
 * Returns latest merged HOEP forecast for a forecaster from a list of issued forecasts,
 * by taking the latest issued HOEP forecast for each hour
 * @param {Array} forecasts the individual forecasts for a given forecaster that needs
 * to be merged
 * @returns {Object} a forecast object containing the merged data array with each hour having
 * latest HOEP value from list of forecasts
 */
export const mergeForecasts = (forecasts) => {
  /*
   * Clean up the forecasts so that if they contain a full day's worth of 
   * zero $ it likely means it was an erroneous forecast - See task #121 on DevOps 
   */
  const cleansedForecasts = _.chain(forecasts)
    .forEach(f => {
      const cleanedData = _.chain(f.data)
        .groupBy(p => {
          const day = moment(p.t).add(-1, "h").format('LL');
          return day;
        })
        .map((pts, day) => {
            const sumValue = _.sumBy(pts, p => p.value);
            if(sumValue != 0){
              return pts;
            }
        })
        .filter(pts => pts !== undefined)
        .flatten()
        .value();
      f.data = cleanedData;
    }).value();
  const mergedData = _.chain(cleansedForecasts)
    .orderBy(f => f.createdDate, "desc")
    .flatMap(f => f.data)
    .uniqBy(d => d.t)
    .orderBy(d => d.t).value();

  var mergedForecast = _.chain(forecasts).last().cloneDeep().value();
  if (mergedForecast !== undefined) {
    mergedForecast.startDate = mergedData[0].t;
    mergedForecast.data = mergedData;
  }

  return mergedForecast;
};

/// REDUCER
export const reducer = produce(
  (draft, action) => {
    switch (action.type) {
      case SET_FORECASTS_START_DATE:
        draft.startDate = action.payload.startDate;
        draft.endDate = action.payload.endDate;
        break;
      case REQUEST_HOEP_FORECASTS:
        draft.data.hoep.all = {};
        draft.data.loading = true;
        break;
      case RECEIVE_HOEP_FORECASTS:
        action.payload.forecasts.forEach(f => {
          f.source = f.model;
          f.forecaster = f.model;
          f.data = f.data.map(p => { return { ...p, y: p.value };});
        });
        action.payload.forecasts = _.chain(action.payload.forecasts)
          .filter(f => f !== undefined)
          .filter(f => f.forecaster !== "Mr. Roboto")
          .value();
        // group all forecasts by forecaster, so that we can create latest merged forecast for each
        var groupedForecasts = _.chain(action.payload.forecasts)
          .cloneDeep()
          .groupBy(f => f.forecaster)
          .map((forecasts, forecaster) => ({ forecaster, forecasts: _.sortBy(forecasts, f => f.createdDate) })).value();
        var mergedForecasts = _.chain(groupedForecasts).map((f) => mergeForecasts(f.forecasts)).value();
        // save unmerged and merged forecasts in store for future reference
        draft.data.hoep.all = action.payload.forecasts;
        draft.data.hoep.merged = mergedForecasts;
        const t2Date = moment(draft.startDate).subtract(12, "h").toISOString();
        // 
        draft.data.hoep.tMinus1 = _.chain(groupedForecasts)
          .map((f) => mergeForecasts(f.forecasts.filter(x => x.createdDate <= t2Date)))
          .filter(f => f !== undefined)
          .filter(f => f.forecaster === "NeuroRoboto")
          .forEach(f => f.forecaster += " (T-1)")
          .value();
        draft.data.loading = false;
        draft.lastUpdatedAt = moment();
        break;
      case REQUEST_SUBMITTED_HOEP_FORECAST:
        draft.data.hoep.submitted = {};
        draft.data.loading = true;
        break;
      case RECEIEVE_SUBMITTED_HOEP_FORECAST:
        // update the forecaster and source to be the model value
        action.payload.forecast.forEach(f => {
          f.source = f.model;
          f.forecaster = f.model;
        });
        var mergedForecast = mergeForecasts(action.payload.forecast);
        draft.data.hoep.submitted = action.payload.forecast.length &&
          action.payload.forecast[0].data.length ? mergedForecast : {};
        draft.data.loading = false;
        break;
      case SUBMIT_HOEP_FORECAST:
        draft.data.hoep.submitted = {};
        draft.data.loading = true;
        break;
      case RECEIVE_ADDED_FORECAST:
        // update the forecaster and source to be the model value
        action.payload.forecast.source = action.payload.forecast.model;
        action.payload.forecast.forecaster = action.payload.forecast.model;
        draft.data.hoep.submitted = action.payload.forecast;
        draft.data.loading = false;
        break;
    }
  },
  initialState
);