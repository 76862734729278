import React from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Footer } from './Footer';
import FlexView  from 'react-flexview';

const menu = [
  {
    name: "",
    icon: "bolt",
    text: "HOEP Forecasts",
    url: "/forecasts",
    submenu: [
      {
        name: "",
        text: "Add",
        url: "/forecasts/add",
      },
      {
        name: "",
        text: "Latest",
        url: "/forecasts/latest",
      }
    ]
  }
];

export const createSideNavMenuItems = (menu, isExpanded) => {
  const createSingleMenuItem = (item, index) => {
    var menuItem = (
      <NavItem eventKey={item.url}>
        <NavIcon>
          <FontAwesomeIcon
            icon={item.icon}
            style={{ fontSize: '1.75em' }}
          />
        </NavIcon>
        <NavText>
          {item.text}
        </NavText>
        {item.submenu ? item.submenu.map(function (subitem) {
          return (<NavItem className={isExpanded ? "subMenuItemExpanded" : ''} eventKey={subitem.url}><NavText>
            {subitem.text}
          </NavText></NavItem>)
        }).map(function (child, i) {
          return React.cloneElement(child, { key: i });
        }) : ""}
      </NavItem>
    );
    return menuItem;

  };

  var navItems = menu.map(function (item, index) {
    return (
      createSingleMenuItem(item, index)
    );
  }).map(function (child, i) {
    return React.cloneElement(child, { key: i });
  });

  return navItems;
};

export const NavMenu = props => {
  var menuItems = createSideNavMenuItems(menu, props.expanded);
  return (
    <FlexView column width={props.expanded ? 240 : 64} height='100%' vAlignContent='top'
      className="sideNavContainer">
      <div className="sideNavMain" >
        <SideNav expanded={props.expanded}
          onSelect={(selected) => (props.onSelect(selected, props.location, props.history))}
          onToggle={props.onToggle} >
          <SideNav.Toggle />
          <SideNav.Nav selected={props.selected} className="sideNav">
            {menuItems}
          </SideNav.Nav>
        </SideNav>
      </div>
      <FlexView vAlignContent='center' hAlignContent='center' className="sideNavFooter">
        <Footer version={props.version} expanded={props.expanded} />
      </FlexView>

    </FlexView>
  )
};