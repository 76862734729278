import React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

class SelectCellRenderer extends React.Component {
  render() {
    return <span>{this.props.valueFormatted}</span>;
  }
}

export function GetFrameworkComponents() {
  return {
    selectCellRenderer: SelectCellRenderer
  };
}

/**
 * Converts a typical data structure where each object (i.e. column) contains the relevant data in its own 
 * child 'data' property. The specified groupByColumn is the property of the data through which
 * the data is grouped across all objects (i.e. column). The columnNames contains the object/column names
 * of the intere
 * @param {Array} data - The data that needs converting, with each object/column containing the data in 
 * data property
 * @param {string} groupByColumn - The property (of the data) by which we will group the data (e.g. time - t)
 * @param {string} columnNames - The name of all of the objects/columns that will be shown on the table (e.g. IESO)
 * @param {string} dataColumnProperty - where in each object is the name of the object stored (e.g. 'source')
 * @returns {Object} returns the data converted in table format which ag-grid can interpret
 */
export function ConvertDataToTableFormat(data, groupByColumn, columnNames, dataColumnProperty) {
  var dataWithNames = _.flatMap([...data], function (d) {
    if (d.data) {
      return d.data.map(o => {
        var a = {};
        // add the column of interest containing all data required
        if (_.indexOf(columnNames, d[dataColumnProperty]) !== -1) {
          a[d[dataColumnProperty]] = o;
        }
        return a;
      });
    }
  });

  var tableData = _(dataWithNames).orderBy(groupByColumn, 'asc')
    .groupBy(d => {
      return d[Object.keys(d)[0]][groupByColumn];
    })
    .toPairs()
    .map(function (currentItem) {
      // each new data point to have field groupByColumn,
      // and all other column data in individual fields named after the columns
      var a = _.zipObject([groupByColumn, "data"], currentItem);
      a = _.extend(a, ...a.data);
      delete a.data;
      return a;
    }).value();

  // TODO: Should be something else than o.t not all data will be having t value
  return _.sortBy(tableData, [function (o) { return o.t; }]);
};

/**
 * Gets the column definitions from the specified columns object. Each column object within array
 * must contain: name (as defined in the table data), field (property accessor for value from data). It
 * should contain: headerName (for column header), cellClass (for formatting), valueFormatter 
 * (from ValueFormatters - how to format the value for given column)
 * @param {Array} columns the columns for which we want the column definitions
 * @returns {Object} a column definition structure
 */
export function GetColumnDefs(columns) {
  var customColumns = columns.map(c => {
    return {
      cellRendererFramework: SelectCellRenderer,
      headerName: c.headerName,
      children: c.children !== undefined ? c.children : [
        {
          headerName: c.subHeaderName !== undefined ? c.subHeaderName : "",
          field: c.field,
          valueFormatter: c.valueFormatter,
          valueGetter: function (params) {
            return params.data[c.name] !== undefined ? params.data[c.name][c.field] : "";
          },
          valueSetter: c.valueSetter,
          cellStyle: c.cellStyle,
          cellRenderer: 'selectCellRenderer'
        }
      ]

    };
  });

  return [...customColumns];
}

// Struct for defining default columns for reuse. For now it's only the DateTime Column group
// 1. DateTimeColumn - two group column that has the date and time component separate
// 2. ... add more as required
export const DefaultColumns = {
  DateTimeColumn: (headerName, childrenHeaderNames) => {
    const columns = {
      headerName: "Trading Hour",
      children: [
        {
          headerName: "Date",
          field: "t",
          editable: function (params) {
            return false;
          },
          valueFormatter: function (params) {
            const t = params.data.t;
            return moment(t).hours() !== 0 ?
              moment(t).format("YYYY-MM-DD") : moment(t).hours(-1).format("YYYY-MM-DD");
          }
        },
        {
          headerName: "Hour",
          field: "t",
          editable: function (params) {
            return false;
          },
          valueFormatter: function (params) {
            const t = params.data.t;
            return moment(t).format("kk");
          }
        }
      ]
    }
    return columns;
  },
  MetricCategoryColumn: () => {
    const columns = {
      headerName: "Metric",
      field: "label"
    };
    return columns;
  },
  AveragesCategoryColumn: () => {
    const columns = {
      headerName: "Average",
      field: "category"
    };
    return columns;
  },
  SubmissionDatesColumn: () => {
    const columns = {
      headerName: "Submission Date",
      field: "label"
    };
    return columns;
  }
};

// Struct for defining default value formatters for reuse.
// 1. currency - gets the params struct and formats the value property as a currency
// 2. ... add more as required
export const ValueFormatters = {
  currency: (params) => {
    if (params.value === "" || isNaN(params.value)) return "";
    const value = typeof(params.value) === "string" ? Number(params.value.replace(/[\$,]/g, ''))
      : params.value;
    return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(value);
  }
};

