import _ from 'lodash';

/**
 * Function combines the arguments of objects (any length) together
 * using lodash concatenate and cloneDeep functions
 * @param {Object} data any number of objects that need combining
 * @returns {Object} a single object with all arguments combined 
 */
export const CombineData = (...data) => {
  var combinedData = _.concat(...data);
  var validData = true;
  combinedData = combinedData.filter(d => !_.isEmpty(d));
  //combinedData.forEach(d => _.isEmpty(d) ? validData = false : "");
  return validData ? _.cloneDeep(combinedData) : [];
};

/**
 * Currently not used - Calculates the net difference between two datasets assuming
 * they have the same starting time point. They can be different lengths
 * @param {Object} firstDataset the first dataset that we want to subtract from (y value)
 * @param {Object} secondDataset the second dataset that we want to subtract from first dataset
 * @returns {Object} the dataset with net difference in y value between first and second dataset
 */
export const NetDifference = (firstDataset, secondDataset) => {

  var data = firstDataset.data.map(function (item, index) {
    if (index >= secondDataset.data.length) {
      return;
    }
    return {
      t: item.t,
      y: index < secondDataset.data.length ? item.y - secondDataset.data[index].y : item.y
    };
  });
  return _.filter(data, p => p !== undefined);
};