import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexView from 'react-flexview';
import { Button } from 'react-bootstrap';


export const UpdateButton = (props) => {
  return (
    <div>
      <Button bsStyle="normal"
        disabled={props.isLoading}
        onClick={() => props.isLoading ? null : props.updateFunction(props.selectedDate)}>
        {!props.isLoading ? 
          <FlexView vAlignContent='center' width='100%'>
            <FlexView column marginRight={8}>{props.textActive}</FlexView>
            {props.iconActive ?
              <FlexView column ><FontAwesomeIcon size="1x" icon={props.iconActive} /></FlexView>
              : ''} 
          </FlexView>
          :
          <FlexView vAlignContent='center' width='100%'>
            <FlexView column marginRight={4}>{props.textDisabled}</FlexView><FlexView column ><FontAwesomeIcon size="1x" icon="spinner" spin /></FlexView>
          </FlexView>
        }
      </Button>
    </div>  
  )
}
