import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as forecastActionCreators } from '../../actions/Forecasts';
import { actionCreators as historicalActionCreators } from '../../actions/Historicals';
import { PriceForecastTable, MetricTable, AveragesMetricTable, SubmissionTable } from '../common/ForecastTable';
import _ from 'lodash';
import moment from 'moment-timezone';
import FlexView from 'react-flexview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import chartColours from '../chart/chartColours.json'
import { ChartComponent, FilterDatasets } from '../chart/Chart';
import { ChartAndTableView } from './ChartAndTableView';
import { CombineData } from './Common';
import { getHOEPforecastMetrics } from '../../selectors/Forecasts';
import { DateRange } from '../common/RangeDatePicker';
import { AddDayAnnotations, UpdateHalfDayAnnotations } from '../chart/HelperFunctions';


const allActionCreators = { ...forecastActionCreators, ...historicalActionCreators };

/**
 * Component for showing HOEP forecasts on chart and table format along with the 
 * historicals (if applicable). The presentantion breakdown is: 1) date picker, 
 * 2) chart showing the data, 3) table showing metrics, 4) actual data values in table format
 */
class HOEPForecasts extends ChartAndTableView {
  constructor(props) {
    super(props);
    const startDate = props.startDate;
    const endDate = props.endDate;
    this.state = {
      ...this.state,
      anonymousMode: props.anonymousMode,
      startDate: startDate,
      endDate: endDate
    };
  }

  get displayName() { return 'HOEPForecasts'; }

  checkPropsForData = (nextProps) => {
    const loadingStatus = nextProps.forecasts.loading || nextProps.historicals.data.loading;
    if (!loadingStatus) {
      var cForecasts = _(nextProps.forecasts).clone();
    

      if(nextProps.anonymousMode){
        const startDateIso = moment(this.props.startDate).utc().format();
        var traderIndex = 0;
        cForecasts.merged = _(nextProps.forecasts.merged)
          .filter(x => x.forecaster != "NeuroRoboto" && _(x.data).last().t > startDateIso)
          .map((x) => {
            traderIndex++;
            x.forecaster = "Trader " + traderIndex;
            // x.model =  "Trader " + traderIndex;
            return x;
          })
          .concat(_(nextProps.forecasts.merged)
          .filter(x => x.forecaster == "NeuroRoboto").value())
          .value();

        const filteredTraders = _(cForecasts.merged).map(x => x.model)
          .value();

        traderIndex = 0;
        cForecasts.all = _(nextProps.forecasts.all)
          .filter(x => x.forecaster != "NeuroRoboto" && _(filteredTraders).indexOf(x.forecaster) !== -1)
          .groupBy(x => x.model).map((x) => {
            traderIndex++;
            const maxForecastDate = _(_(x).maxBy(z => _(z.data).last().t).data).last().t;
            if(maxForecastDate < startDateIso)
              return undefined;
            return _(x).forEach((y) => {
              y.forecaster = "Trader " + traderIndex;
              // y.model =  "Trader " + traderIndex;
            });
          }).flatMap()
          .filter(x => x !== undefined)
          .concat(_(nextProps.forecasts.all)
          .filter(x => x.forecaster == "NeuroRoboto").value())
          .value();          
      }
  
      var historicals = CombineData(nextProps.historicals.data.hoep);
      
      // calcualte METRICS
      const maxEndDate = moment(nextProps.endDate).add(1, "h");
      const cutOffSubmitDateT_1 = moment(nextProps.historicals.startDate).add(-12, "h");
      const cutoffSubmitDateT_2 = moment(nextProps.historicals.startDate).add(-36, "h");
      const latestMetrisData = getHOEPforecastMetrics(nextProps.startDate,
        maxEndDate, undefined, "Latest")(
        {
          forecasts: cForecasts,
          historicals: nextProps.historicals
          });
      // T-1 RMSE calculated by considering all forecasts and filtering out for any ones
      // issued after noon the day before startDate
      const T_1metricsTableData = getHOEPforecastMetrics(nextProps.startDate,
        maxEndDate, cutOffSubmitDateT_1, "T-1") (
          {
            forecasts: cForecasts,
            historicals: nextProps.historicals
          });
      // T-1 RMSE calculated by considering all forecasts and filtering out for any ones
      // issued after noon the day before startDate
      const T_2metricsTableData = getHOEPforecastMetrics(nextProps.startDate,
        maxEndDate, cutoffSubmitDateT_2, "T-2")(
          {
            forecasts: cForecasts,
            historicals: nextProps.historicals
          });

      // COMBINE ALL METRICS
      const combinedMetricsTableData = _(latestMetrisData).concat(T_1metricsTableData).concat(T_2metricsTableData)
        .groupBy(f => f.forecaster);

      // get 1x16 and 1x8 averages for latest forecasts from each forecaster
      const averagesTableData = combinedMetricsTableData
        .map((data, forecaster) =>
          ({
            forecaster: forecaster,
            data: _(data)
              .flatMap(a => a.data)
              .filter(a => !a.label.includes("RMSE"))
              .groupBy(a => a.category)
              .map(o => Object.assign({}, ...o))
              .value()
          })
      ).value();

      // combine all metrics data into one for Metrics table
      const metricsTableData = combinedMetricsTableData
        .map((data, forecaster) =>
          ({
            forecaster: forecaster,
            data: _(data).flatMap(a => a.data).filter(a => a.label.includes("RMSE")).value()
          })
        ).value();

      // Add NeuroRoboto (T-2) as its own foreacast
      const tableT2Data = FilterDatasets(_(cForecasts.tMinus1).filter(f => f !== undefined && f.model == "NeuroRoboto").orderBy("forecaster", "asc").value(),
        nextProps.endDate.diff(nextProps.startDate, "d") + 1, nextProps.startDate);

      // filter datasets to only keep data for date range specified
      const allForecasts = _(CombineData(tableT2Data,
        _(cForecasts.merged).orderBy("forecaster", "asc").value())).orderBy("forecaster", "asc").value();
      const tableData = FilterDatasets(CombineData(historicals, allForecasts),
          nextProps.endDate.diff(nextProps.startDate, "d") + 1, nextProps.startDate);
      
      // get the submission dates for latest forecast from each forecaster
      const submissionDatesData = _(allForecasts).orderBy("forecaster", "asc")
        .map(f => {
          return {
            forecaster: f.forecaster,
            data: [{
              label: "Submission Date",
              date: moment(f.createdDate).format("YYYY-MM-DD HH:mm")
            }]
          };
        })
        .value();

      // create chart data based on the table data - but only keep 2 days worth of data
      const chartData = FilterDatasets(tableData,
        nextProps.endDate.diff(nextProps.startDate, "d") + 1, nextProps.startDate);
      chartData.forEach(f => f.label = f.forecaster !== undefined ? f.forecaster : f.source);
      // add colors/styles to each of the chart series
      this.addChartColours(chartData, chartColours);
      console.log(chartColours);

      // capture all these changes in our state object
      this.setState({
        tableData: tableData, chartData: chartData, loading: false,
        // for Metrics table
        metricsTableData: metricsTableData,
        // for Averages table
        averagesTableData: averagesTableData,
        // for Submission dates table
        submissionDatesData,
        // update dates
        startDate: nextProps.startDate,
        endDate: nextProps.endDate
      });

    } else {
      this.setState({
        loading: true,
        // update dates
        startDate: nextProps.startDate,
        endDate: nextProps.endDate
      });
    }
  }
  
  onDatesChange({ startDate, endDate }) {
    startDate = moment(startDate).startOf("day").hour(1);
    endDate = moment(endDate).startOf("day").hour(23);
    this.props.setForecastsStartDate({ startDate, endDate }, true);
    this.props.setHistoricalsStartDate({ startDate, endDate }, true);
  }

  getForecastPeriod(unit) {
    var defaultPeriod;
    switch (unit) {
      case 'd':
        defaultPeriod = 2;
        break;
      case 'h':
        defaultPeriod = 48;
        break;
    }
    return this.state.endDate !== null ? this.state.endDate.diff(this.state.startDate, unit) + 1
      : defaultPeriod;
  }

  render() {
    // table html contents
    var table = '';
    // chart html contents
    var chart = '';
    // the full table and chart sections object
    var tableAndChartSections = (<div><FontAwesomeIcon size="2x" icon="spinner" spin /></div>);

    if (!this.state.loading && this.state.tableData.length > 0 && this.state.chartData.length > 0) {
      // METRIC table
      const metricTable = (<MetricTable className='defaultDiv'
          data={this.state.metricsTableData}
      />);
      const metricsSection = moment(this.state.startDate) > moment() ? "" : (<div><h2>Metrics</h2>
        {metricTable}
      </div>);

      // AVERAGES table
      const averagesTable = (<AveragesMetricTable className='defaultDiv'
        data={this.state.averagesTableData}
      />);

      // SUBMISSION table
      const submissionDatesTable = (<SubmissionTable className='defaultDiv'
        submissionDates={this.state.submissionDatesData}
      />);

      // FORECAST table
      table =
        (<PriceForecastTable className='defaultDiv'
          paginationPageSize={48}
          pagination
          forecasts={this.state.tableData}
          defaultSorted={[
            {
              id: "t",
              asc: true
            }
          ]}
        />);

      const numDays = this.getForecastPeriod("d");
      chartOptions.annotations = AddDayAnnotations(numDays);
      chart = (<ChartComponent options={chartOptions}
        dataLoaded={!this.state.loading}
        data={this.state.chartData} />);

      tableAndChartSections =
        (<div className="defaultDiv">
          <br />
          {chart}
          {metricsSection}
          <h2>Averages</h2>
          {averagesTable}
          <h2>Submission Dates</h2>
          {submissionDatesTable}
          <h2>Data</h2>
          {table}
        </div>
        );
    }

    return (
      <div className="defaultDiv">
        <h1>HOEP Forecasts</h1>
        <FlexView vAlignContent='center' hAlignContent='left' height='40px'>
          <FlexView className='labelDiv' column width='180px' hAlignContent='left' >
            <strong>Select Forecast Period:</strong>
          </FlexView>
          <FlexView column hAlignContent='left' >
            <DateRange
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              minDate={moment().startOf("year").add(-10, "y")}
              maxDate={moment().add(10, "d")}
              onDatesChange={this.onDatesChange.bind(this)}
            />
          </FlexView>
        </FlexView>
        {tableAndChartSections}
      </div>
    );
  }
}

/**
 * Map the state and actions correctly for use with the HOEPForecasts component
 */
export default connect(
  state => {
    return {
      startDate: state.forecasts.startDate,
      endDate: state.forecasts.endDate,
      forecasts: {
        ...state.forecasts.data.hoep,
        loading: state.forecasts.data.loading
      },
      historicals: state.historicals
    };
  },
  dispatch => bindActionCreators(allActionCreators, dispatch)
)(HOEPForecasts);

///////////////////// CHART OPTIONS AND CUSTOMIZATION /////////////////////////
/**
 * The chart options for the chart showing the HOEP forecasts and historical
 */
const chartOptions = {
  title: "HOEP Forecasts",
  toggleVisibility: true,
  // y axes options
  yLabel: "HOEP ($)",
  yMarginTop: 5,
  yMarginBottom: 5,
  yStepFactor: 5,
  yMajorEnabled: true,
  // x axes options
  xMajorEnabled: true,
  xLabel: "Date",
  xMinorToMajor: 2,
  xNumUnitsToMaxTime: 1,
  xNumUnitsToMinTime: 1,
  xReferenceHourEnabled: false,
  //x.autoSkip : false,
  xTicksMaxRotation: 60,
  xTime: {
    round: false,
    unit: 'hour',
    unitStepSize: 1,
    displayFormats: {
      'hour': 'DD-MM-YYYY kk',
    }
  },
  // zoom/pan options
  zoom: true,
  pan: true,
  panMode: 'xy',
  yStepSizeFunction: function (minValue, maxValue, options) {
    const yRange = parseFloat(maxValue) - parseFloat(minValue);
    if (yRange < 50)
      return 5;
    if (yRange < 100)
      return 10;
    if (yRange < 200)
      return 20;
    else
      return 50;
  },
  xStepSizeFunction: function (min, max, options) {
    const xHourRange = moment(max).diff(min, "h");
    if (xHourRange < 100)
      return 2;
    else if (xHourRange < 200)
      return 4;
    else
      return 16;
  },
  annotations: AddDayAnnotations(),
  annotationUpdateFunctions: [
    UpdateHalfDayAnnotations
  ]
};
