import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavMenu } from './SideNav';
import { Header } from './Header';
import moment from 'moment-timezone';
import { actionCreators as forecastActionCreators } from '../../actions/Forecasts';
import { actionCreators as historicalActionCreators } from '../../actions/Historicals';
import styled from 'styled-components';
import 'core-js/fn/string/includes';

const allActionCreators = { ...forecastActionCreators, ...historicalActionCreators };

// sets the style for the main div (right of the sidebar)
const Main = styled.main`
    position: relative;
    height: 100%;
    overflow: visible;
    transition: all .15s;
    padding: 0 20px;
    margin-left: ${props => (props.expanded ? 240 : 64)}px;
`;


export class Layout extends PureComponent {
  constructor() {
    super();

    const startDate = moment().startOf("day").add(-1, "d").hour(1);
    const endDate = moment().startOf("day").hour(23);

    this.state = {
      selected: '',
      expanded: true,
      startDate: startDate,
      endDate: endDate
    };


    this.performSiteUpdate = this.performSiteUpdate.bind(this);
    this.initDates = this.initDates.bind(this);

  }

  componentDidMount() {
    this.initDates({ startDate: this.state.startDate, endDate: this.state.endDate });
    this.performSiteUpdate();
  }

  componentWillReceiveProps(nextProps) {
    console.log("New startDate: ", nextProps.startDate.format());
    this.setState({
      // update dates
      startDate: nextProps.startDate,
      endDate: nextProps.endDate
    });
  }

  onMenuSelect = (selected, location, history) => {
    this.setState({ selected: selected });
    if (location.pathname !== selected) {
      history.push(selected);
    }
  };

  // to control the toggle of the side nav menu,
  // and push the main div to right
  onMenuToggle = (expanded) => {
    this.setState({ expanded: expanded });
  };

  handleDateChange = (date) => {
    this.setState({ startDate: date });
    this.props.setForecastsStartDate(date, false);
    this.props.setHistoricalsStartDate(date, false);
  }

  initDates = ({ startDate, endDate }) => {
    this.props.setForecastsStartDate({ startDate, endDate });
    this.props.setHistoricalsStartDate({ startDate, endDate });
  }

  performSiteUpdate = () => {
    this.props.getHOEPforecasts({
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
    this.props.getHOEPhistoricals({
      startDate: this.state.startDate,
      endDate: this.state.endDate
    }, true);
  }


  render() {
    const location = this.props.location;
    const history = this.props.history;
    const { expanded } = this.state;
    return (
      <div style={{ height: "100%"}}>
        <Main expanded={expanded}>
          <Header onDateChange={this.handleDateChange}
            className="header"
            selectedDate={this.props.forecasts.startDate}
            lastUpdatedAt={this.props.forecasts.lastUpdatedAt}
            loadingStatus={this.props.loading}
            retrieveFunction={this.performSiteUpdate}
          />
          <div className="mainContent" >
            {this.props.children}
          </div>
        </Main>
        <NavMenu selected={location.pathname}
          location={location}
          history={history}
          onSelect={this.onMenuSelect}
          onToggle={this.onMenuToggle}
          selectedDate={this.props.forecasts.startDate}
          onDateChange={this.handleDateChange}
          expanded={this.state.expanded}
          maxDate={moment()}
          version={this.props.general.app.version}
        />
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      ...state,
      startDate: state.forecasts.startDate,
      endDate: state.forecasts.endDate,
      loading: state.forecasts.data.loading || state.historicals.data.loading
    };
  },
  dispatch => bindActionCreators(allActionCreators, dispatch)
)(Layout);
