import { map, mergeMap, switchMap, filter} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import moment from 'moment-timezone';
// import WebsocketService from '../components/common/WebSocketService';
// import { store } from '../store/configureStore';
// fetcher with authentication (from react-adal), returning json object
// TODO - work on catcher for error
import { authApiFetchJson } from '../auth/auth';

// HOEP Forecast Action Types
export const SET_HISTORICALS_START_DATE = 'SET_HISTORICALS_START_DATE';
export const REQUEST_HOEP_HISTORICALS   = 'REQUEST_HOEP_HISTORICALS';
export const RECEIVE_HOEP_HISTORICALS   = 'RECEIVE_HOEP_HISTORICALS';

/// ACTIONS
export const actionCreators = {
  setHistoricalsStartDate: ({ startDate, endDate }, performUpdate) => ({
    type: SET_HISTORICALS_START_DATE,
    payload: {
      startDate: startDate,
      endDate: endDate,
      // send signal to update forecasts,
      // unless update argument sent here was false
      performUpdate: performUpdate !== undefined ? performUpdate : true
    }
  }),
  getHOEPhistoricals: ({ startDate, endDate }) => ({
    type: REQUEST_HOEP_HISTORICALS,
    payload: {
      startDate: startDate,
      endDate: endDate
    }
  })
};

/// EPICS
export const epics = [
  //startDate: 
  (action$) => {
    return action$.pipe(
      ofType(SET_HISTORICALS_START_DATE),
      filter((action) => action.payload.performUpdate),
      mergeMap((action) => [actionCreators.getHOEPhistoricals({
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      })])
    );
  },
  //HOEPhistoricals: 
  (action$) => {
    return action$.pipe(
      ofType(REQUEST_HOEP_HISTORICALS),
      switchMap(action => {
        return from(
          authApiFetchJson(
            `api/PriceData/GetHOEPHistoricals?startDate=${moment(action.payload.startDate).format()}` +
            `&endDate=${moment(action.payload.endDate).format()}`))
          .pipe(
            map(historicals => {
              return { historicals: historicals };
            })
          );
      }),
      map(response => {
        return {
          type: RECEIVE_HOEP_HISTORICALS,
          payload: { historicals: response.historicals }
        };
      })
    );
  }
];

// EPIC helper for historicals


// WEBSOCKET COMMUNICATION TEMPLATE
//const receiverGeneratedForecast = (msg, forecast) => {
//  switch (msg) {
//    case msgUpdatedGeneratedForecastWebsocketType:
//      // dispatch the received generated forecast to the store  
//      store.dispatch({ type: receieveGeneratedForecastType, forecasts: forecast });
//      break;
//    case msgCreatedGeneratedForecastWebsocketType:
//    default:
//      // update the selected date (in case we move into tomorrow), and update all 
//      // forecasts
//      store.dispatch(actionCreators.setSelectedDate(moment(), true));
//      // dispatch the received generated forecast to the store  
//      store.dispatch({ type: receieveGeneratedForecastType, forecasts: forecast });
//  }
//};

//WebsocketService.registerMessageReceiver(receiveGeneratedForecastWebsocketType, receiverGeneratedForecast);
