import React from 'react';
import moment from 'moment-timezone';

export const Footer = (props) => {
  const text = props.expanded ? (<div> { props.version } < br />
    Goce Jankuloski <br />
    &copy; OPG {moment().format("YYYY")} </div>) : (<div> {props.version} </div>)
  return (
    (text)
  );
};