// This must be the first line in src/index.js
import 'airbnb-browser-shims';

import { runWithAdal } from 'react-adal';
import { authContext } from './auth/auth';
const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {

  // eslint-disable-next-line
  require('./indexApp.js');

}, DO_NOT_LOGIN);