import { map, mergeMap, switchMap, filter} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import moment from 'moment-timezone';
//import WebsocketService from '../components/common/WebSocketService';
//import { store } from '../store/configureStore';
// fetcher with authentication (from react-adal), returning json object
// TODO - work on catcher for error
import { authApiFetchJson } from '../auth/auth';
import { getUserFullName } from '../auth/auth';

// HOEP Forecast Action Types
export const SET_FORECASTS_START_DATE = 'SET_FORECASTS_START_DATE';
export const REQUEST_HOEP_FORECASTS   = 'REQUEST_HOEP_FORECASTS';
export const RECEIVE_HOEP_FORECASTS = 'RECEIVE_HOEP_FORECASTS';
export const SUBMIT_HOEP_FORECAST = 'SUBMIT_HOEP_FORECAST';
export const RECEIVE_ADDED_FORECAST = 'RECEIVE_ADDED_FORECAST';
export const REQUEST_SUBMITTED_HOEP_FORECAST = 'REQUEST_SUBMITTED_HOEP_FORECAST';
export const RECEIEVE_SUBMITTED_HOEP_FORECAST = 'RECEIEVE_SUBMITTED_HOEP_FORECAST';

/// ACTIONS
export const actionCreators = {
  setForecastsStartDate: ({ startDate, endDate }, performUpdate) => ({
    type: SET_FORECASTS_START_DATE,
    payload: {
      startDate: startDate,
      endDate: endDate,
      // send signal to update forecasts,
      // unless update argument sent here was false
      performUpdate: performUpdate !== undefined ? performUpdate : true
    }    
  }),
  getHOEPforecasts: ({ startDate, endDate }) => ({
    type: REQUEST_HOEP_FORECASTS,
    payload: {
      startDate: startDate,
      endDate: endDate
    }
  }),
  getSubmittedHOEPforecast: ({ startDate, endDate }) => ({
    type: REQUEST_SUBMITTED_HOEP_FORECAST,
    payload: {
      startDate: startDate,
      endDate: endDate
    }
  }),
  submitHOEPforecast: forecast => ({
    type: SUBMIT_HOEP_FORECAST,
    payload: {
      forecast: forecast
    }
  })
};

/// EPICS
export const epics = [
  //startDate:
  (action$) => {
    return action$.pipe(
      ofType(SET_FORECASTS_START_DATE),
      filter((action) => action.payload.performUpdate),
      mergeMap((action) => [actionCreators.getHOEPforecasts({
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      })])
    );
  },
  //HOEPforecasts:
  (action$) => {
    return action$.pipe(
      ofType(REQUEST_HOEP_FORECASTS),
      switchMap(action => {
        return from(authApiFetchJson(`api/PriceData/GetAllHOEPForecasts?startDate=${moment(action.payload.startDate).format()}`
          + `&endDate=${moment(action.payload.endDate).format()}`))
          .pipe(
            map(forecasts => {
              return { forecasts: forecasts };
            })
          );
      }),
      map(response => {
        return {
          type: RECEIVE_HOEP_FORECASTS,
          payload: { forecasts: response.forecasts }
        };
      })
    );
  },
  //retrieveSubmittedHOEPforecast:
  (action$) => {
    return action$.pipe(
      ofType(REQUEST_SUBMITTED_HOEP_FORECAST),
      switchMap(action => {
        return from(authApiFetchJson(`api/PriceData/GetHOEPForecastsFor?startDate=`
          + `${moment(action.payload.startDate).format()}`
          + `&endDate=${moment(action.payload.endDate).format()}`
          + `&forecaster=${getUserFullName().last}`
          + `&merge=true`))
          .pipe(
            map(forecast => {
              return { forecast: forecast };
            })
          );
      }),
      map(response => {
        return {
          type: RECEIEVE_SUBMITTED_HOEP_FORECAST,
          payload: { forecast: response.forecast }
        };
      })
    );
  },
  //submitHOEPforecasts: 
  (action$) => {
    return action$.pipe(
      ofType(SUBMIT_HOEP_FORECAST),
      switchMap(action => {
        return from(authApiFetchJson(`api/PriceData/AddHOEPForecast`,
          {
            method: 'post',
            body: action.payload.forecast,
            headers: { "Content-Type": "application/json" }
          }))
          .pipe(
            map(forecast => {
              return { forecast: forecast };
            })
          );
      }),
      map(response => {
        return {
          type: RECEIVE_ADDED_FORECAST,
          payload: { forecast: response.forecast }
        };
      })
    );
  }
];

// EPIC helper for forecasts


// WEBSOCKET COMMUNICATION TEMPLATE
//const receiverGeneratedForecast = (msg, forecast) => {
//  switch (msg) {
//    case msgUpdatedGeneratedForecastWebsocketType:
//      // dispatch the received generated forecast to the store  
//      store.dispatch({ type: receieveGeneratedForecastType, forecasts: forecast });
//      break;
//    case msgCreatedGeneratedForecastWebsocketType:
//    default:
//      // update the selected date (in case we move into tomorrow), and update all 
//      // forecasts
//      store.dispatch(actionCreators.setSelectedDate(moment(), true));
//      // dispatch the received generated forecast to the store  
//      store.dispatch({ type: receieveGeneratedForecastType, forecasts: forecast });
//  }
//};

//WebsocketService.registerMessageReceiver(receiveGeneratedForecastWebsocketType, receiverGeneratedForecast);
