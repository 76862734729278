import React, { Component} from 'react';
import _ from 'lodash';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

export class GridTable extends Component {
  gridApi = {};
  gridColumnApi = {};

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.columnDefs,
      frameworkComponents: props.frameworkComponents,
      defaultColDef: { resizable: true, editable: true },
      rowData: props.rowData
    };

    this.sizeToFit = this.sizeToFit.bind(this);
    this.autoSizeAll = this.autoSizeAll.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.handlePasteData = this.handlePasteData.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleCellOver = this.handleCellOver.bind(this);

    // editable off screen div for pasting data
    this.setPasteDiv = element => {
      this.pasteDiv = element;

    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      ...this.state,
      rowData: props.rowData
    });
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // by default sizeTofit
    this.sizeToFit();
  };

  sizeToFit() {
    const gridApi = this.gridApi;
    gridApi.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        gridApi.sizeColumnsToFit();
      });
    });
  }

  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

  handleMouseDown() {
    const firstCell = this.gridApi.getFocusedCell();
    if (firstCell) {
      this.removeSelectedColour();
      console.log(this.gridApi.getDisplayedRowAtIndex(firstCell.rowIndex));
      this.setState({
        rangeSelection: true,
        range: {
          start: { row: firstCell.rowIndex, col: firstCell.column },
          end: { row: firstCell.rowIndex, col: firstCell.column }
        }
      });
    }
  }


  handleMouseUp() {
    this.pasteDiv.focus();
    this.setState({ rangeSelection: false });
  }

  removeSelectedColour() {
    var params = {};
    var instances = this.gridApi.getCellRendererInstances(params);

    instances.forEach(cell => {
      const divCell = cell.eParentElement.parentNode;
      if(divCell !== null) divCell.classList.remove("ag-cell-selected");
    });

  }

  colourSelectedCells() {
    const instances = this.getSelectedCells();
    instances.forEach(cell => {
      const divCell = cell.eParentElement.parentNode;
      divCell.classList.add("ag-cell-selected");
    });
  }

  getSelectedRows(desiredNumRows) {
    var endRowIndex = this.state.range.end.row;
    if (desiredNumRows !== undefined) {
      endRowIndex = Math.min(parseInt(desiredNumRows, 10) + this.state.range.start.row - 1,
        this.gridApi.getDisplayedRowCount() - 1);
    }

    return _.range(this.state.range.start.row,
      endRowIndex + 1, 1)
      .map(i => this.gridApi.getDisplayedRowAtIndex(i));
  }

  getSelectedCells() {
    const selectedRowNodes = this.getSelectedRows();
    var params = {
      columns: this.state.range.cols,
      rowNodes: selectedRowNodes
    };
    return this.gridApi.getCellRendererInstances(params);
  }

  handleCellOver(event) {
    if (this.state.rangeSelection) {
      event.node.setSelected(false);
      var start = this.state.range.start;
      var end = this.state.range.end;

      // if new row below start than update end
      if (event.node.rowIndex >= this.state.range.start.row) {
        end.row = event.node.rowIndex;
      } else {
        end.row = start.row;
        start.row = event.node.rowIndex;
      }

      this.setState({
        range: {
          start: start,
          end: end
        }
      }, this.colourSelectedCells);

    }
  }

  handlePasteData(e) {
    var pastedData = "";
    if (window.clipboardData && window.clipboardData.getData) {
      pastedData = window.clipboardData.getData('Text');
    } else if (e.clipboardData && e.clipboardData.getData) {
      pastedData = e.clipboardData.getData('text/plain');
    }
    var pastedValues = pastedData.split("\n");
    pastedValues.pop();

    // go through all selected rows and put in new data
    const desiredNumRows = Math.max(pastedValues.length,
      this.state.range.end.row - this.state.range.start.row + 1);
    const rows = this.getSelectedRows(desiredNumRows);
    const dataToUpdate = rows.map((row, i) => {
      var data = row.data;
      const col = this.state.range.start.col;
      if (col.originalParent !== undefined) {
        data[col.originalParent.colGroupDef["headerName"]][col.colDef.field]
          = pastedValues[i % pastedValues.length];
      }
      return data;
    });

    this.gridApi.updateRowData({ update: dataToUpdate });

    // need to update callback
    this.props.updateDataCallback(dataToUpdate);

    // prevent the pasting from happenning to the pasteDiv
    e.preventDefault();
  }

  render() {
    const inputDiv =
      (<div ref={this.setPasteDiv} contentEditable={true}
        onPaste={this.handlePasteData}
        className="hiddenInput"
      />);

    return (
      <div
        onKeyDown={this.handleKeyDown}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        style={{ width: "100%", height: "100%" }} >
          <div className="grid-wrapper">
            {inputDiv}
            <div ref={this.setGridTable}
              id="myGrid"
              style={{
                height: "100%",
                width: "100%",
              } }
              className="ag-theme-balham"
            >
              <AgGridReact 
                {...this.props}
                onCellMouseOver={this.handleCellOver}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                onGridReady={this.onGridReady}
              />
          </div>
        </div>
      </div>
    );
  }
}