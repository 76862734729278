import React from 'react';
import moment from 'moment-timezone';
import FlexView from 'react-flexview';
import { UpdateButton } from '../common/UpdateButton';
import _ from 'lodash';
import { authContext } from '../../auth/auth';

export const Header = props => (
  <FlexView vAlignContent='center' className={props.className}>
    <FlexView hAlignContent='left' grow={1} shrink  basis='30%'>

    </FlexView>

    <FlexView hAlignContent='center' grow={1} shrink  basis='20%'>
    </FlexView>

    <FlexView hAlignContent='right' grow={1} basis='50%'>
      <FlexView column className='divSpacing' vAlignContent='center'  hAlignContent='right' grow={0.5} width='30%' >
        {props.loadingStatus === false ? "Updated at: " + moment(props.lastUpdatedAt).format("DD/MM/YYYY HH:mm:ss") : "Retrieving data..." }
      </FlexView>
      <FlexView column className='divSpacing' vAlignContent='center' hAlignContent='center'  shrink={false}>
        <UpdateButton selectedDate={moment(props.selectedDate)}
          textDisabled="Updating..."
          iconActive="sync-alt"
          textActive="Refresh"
          updateFunction={props.retrieveFunction}
          isLoading={props.loadingStatus}
      />
      </FlexView>
      <FlexView column className='divSpacing' vAlignContent='center' hAlignContent='center' shrink={false}>
        {authContext.getCachedUser().profile.name}
      </FlexView>
    </FlexView>

  </FlexView>
);

