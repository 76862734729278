import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getUserFullName } from '../auth/auth';
import { mergeForecasts } from '../reducers/Forecasts'
import { CombineData } from '../components/forecast/Common';
// add Mr. Roboto T-2 to the hoep forecasts selector
const getHOEPforecasts = (state) => CombineData(state.forecasts.all, state.forecasts.tMinus1);
const getHOEPhistoricals = (state) => state.historicals.data.hoep;

/**
 * Returns HOEP forecast metrics for the given start and end date period range,
 * based on the state containing hoep forecasts and historicals
 * @param {Date} startDate a start date for the time period in question (moment)
 * @param {Date} endDate an end date for the time period in question (moment)
 * @param {Date} forecastSubmitDate a date past which forecasts will not be considered
 * @param {string} metricLabel the label for the metric in terms of period (Latest, T-2, T-1)
 * for calculation purposes
 * @returns {Object} contains the metrics for each forecast in objec structure}
 */
export const getHOEPforecastMetrics = (startDate, endDate, forecastSubmitDate, metricLabel) => {
  return createSelector(
    [getHOEPforecasts, getHOEPhistoricals],
    (forecasts, historicals) => {
      // grab the latest historical data
      const historicalData = _(_.cloneDeep(historicals)).orderBy('createdDate', 'asc')
        .flatMap(d => d.data)
        .filter(p => moment(p.t) >= startDate && moment(p.t) <= endDate)
        .groupBy('t')
        .toPairs()
        .map(v => {
          var point = _.zipObject(['t', 'data'], [v[0], v[1][v[1].length - 1]]);
          point = _.extend(point, point.data);
          delete point.data;
          return point;
        })
        .value();

      // filter out the forecasts data so it only contains time period specified
      const latestCreatedDate = forecastSubmitDate === undefined ? moment() : forecastSubmitDate;
      var groupedForecasts = _.chain(forecasts)
        .groupBy(f => f.forecaster)
        .map((forecasts, forecaster) => ({ forecaster, forecasts: _.sortBy(forecasts, f => f.createdDate) })).value();

      const forecastsData = _(_.cloneDeep(groupedForecasts)).orderBy('forecaster', 'asc')
        .map(gF => {
          gF.forecasts = _.chain(gF.forecasts).filter(f => moment(f.createdDate) <= latestCreatedDate);
          return mergeForecasts(gF.forecasts);
        })
        .filter(f => f !== undefined)
        .map(f => {
          f.data = f.data.filter(p => moment(p.t) >= startDate && moment(p.t) <= endDate);
          return f;
        })
        .value();

      // calculate error for each forecast
      const errors = forecastsData.map(f => {
        // TEST
        const hrs = [...Array(24).keys()];
        const offPkHrs = [...Array(8).keys()];
        const onPkHrs = hrs.filter(x => !offPkHrs.includes(x));

        var metrics = {};
        metrics = {};
        metrics.forecaster = f.forecaster;
        metrics.data = [];
        metrics.data.push(
          {
            category: metricLabel,
            label: "RMSE " + metricLabel,
            value: getRootMeanSquaredError(historicalData, f.data)
          }
        );
        metrics.data.push(
          {
            category: metricLabel,
            label: "Peak",
            peak: getAverageForHours(f.data, onPkHrs)
          }
        );
        metrics.data.push(
          {
            category: metricLabel,
            label: "Off-Peak",
            offpeak: getAverageForHours(f.data, offPkHrs)
          }
        );
        return metrics;
      });

      return errors;
    }
  );
};

/**
 * Gets the Root Mean Squared error for the data based on the actual (refData)
 * @param {Array} refData an array of {t, y} points which represent the true values
 * @param {Array} data an array of {t, y} points which represent the projected values
 * @returns {Number} RMSE for the data
 */
const getRootMeanSquaredError = (refData, data) => {
  var numMatchingPoints = 0;
  var sumSquareMeanError = _(data).reduce((accum, p) => {
    const actual = _.find(refData, { t: p.t });
    if (actual !== undefined) numMatchingPoints++;
    accum += Math.pow(p.y - (actual !== undefined ? actual.y : p.y), 2);
    return accum;
  }, 0);

  if (numMatchingPoints === 0 || numMatchingPoints !== refData.length) return undefined;

  return Math.sqrt(sumSquareMeanError / numMatchingPoints);
};

/**
 * Initializes an empty forecast given the start date and number of hours. Used
 * when forecaster does not have a forecast for given period
 * @param {any} startDate - the start date as a valid moment object
 * @param {any} numHours - number of hours of forecast
 * @returns {object} - a forecast object with data array of points set to 0
 */
export const initializeEmptyForecast = (startDate, numHours) => {
  if (numHours === undefined) {
    numHours = 48;
  }
  var forecast = {
    forecaster: getUserFullName().last,
    startDate: moment(startDate).toISOString(),
    data: []
  };

  for (var i = 0; i < numHours; i++) {
    forecast.data.push({
      t: moment(startDate).add(i, "h").toISOString(),
      value: 0
    });
  }

  return forecast;
};

/**
 * Gets the average for a given data which is an array data of {t, y} points for the hours in the day 
 * that are in array hours
 * @param {Array} data the data that needs averaging - each element is {t, y}
 * @param {Array} hours the hours that should be averaged on [1 - 24]
 * @returns {Number} the average value as a number
 */
export const getAverageForHours = (data, hours) => {
  if (hours === undefined) {
    return undefined;
  }

  // filter only for data points with specified hours and average the 
  // resulting array
  var average = _(data)
    .filter(p => hours.indexOf(moment(p.t).hours()) !== -1)
    .meanBy(p => p.value);

  return average;
}

