import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../actions/Forecasts';
import { PriceForecastTable } from '../common/ForecastTable';
import _ from 'lodash';
import moment from 'moment-timezone';
import { DateRange } from '../common/RangeDatePicker';
import FlexView from 'react-flexview';
import { UpdateButton } from '../common/UpdateButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartComponent, FilterDatasets } from '../chart/Chart';
import { ChartAndTableView } from './ChartAndTableView';
import { initializeEmptyForecast } from '../../selectors/Forecasts';
import { AddDayAnnotations, UpdateHalfDayAnnotations } from '../chart/HelperFunctions';
import chartColours from '../chart/chartColours.json'


class AddHOEPForecast extends ChartAndTableView {
  constructor(props) {
    super();
    const startDate = moment().startOf("day").add(1, "d").hour(1);
    const endDate = moment().startOf("day").add(2, "d").hour(23);
    this.state = {
      minDate: moment(startDate).add(-1, "d"),
      maxDate: moment().add(10, 'd'),
      startDate: startDate,
      endDate: endDate,
      forecast: initializeEmptyForecast(startDate, endDate.diff(startDate, "h") + 2),
      isSending: props.loading
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.updateForecastData = this.updateForecastData.bind(this);
    this.submitForecast = this.submitForecast.bind(this);
  }

  get displayName() { return 'AddHOEPForecast'; }

  componentDidMount() {
    this.props.getSubmittedHOEPforecast({
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });
  }

  /**
   * Based on next props (forecast) object determine
   * whether to set this object's forecast to the one receieved from backend
   * @param {any} nextProps - props from store
   */
  checkPropsForData(nextProps) {
    const stateToUpdate = {
      isSending: nextProps.loading,
      forecast: initializeEmptyForecast(this.state.startDate, 
        this.state.endDate.diff(this.state.startDate, "h") + 2)
    };

    if (!_.isEmpty(nextProps.forecast)) {
      stateToUpdate.forecast = nextProps.forecast;
      var forecastEndDate = moment(stateToUpdate.forecast.data[stateToUpdate.forecast.data.length - 1].t);
      var newForecast = initializeEmptyForecast(forecastEndDate.add(1, "h"),
        this.state.endDate.diff(forecastEndDate, "h") + 2);
      stateToUpdate.forecast.data.push(...newForecast.data);
      const numDays = this.getForecastPeriod("d");
      stateToUpdate.forecast = FilterDatasets([stateToUpdate.forecast], numDays, this.state.startDate)[0];      
    }
    this.setState(stateToUpdate);
  }

  getForecastPeriod(unit) {
    var defaultPeriod;
    switch (unit) {
      case 'd':
        defaultPeriod = 2;
        break;
      case 'h':
        defaultPeriod = 48;
        break;
    }
    return this.state.endDate !== null ? this.state.endDate.diff(this.state.startDate, unit) + 1
      : defaultPeriod;
  }

  onDatesChange = ({ startDate, endDate }) => {
    startDate = moment(startDate).startOf("day").hour(1);
    endDate = moment(endDate).startOf("day").hour(23);

    const numHours = endDate !== null ? endDate.diff(startDate, "h") + 2 : 48;
    const forecast = initializeEmptyForecast(startDate,
      numHours);
    
    forecast.data.forEach((p, index) => {
      if (index < this.state.forecast.data.length) {
        p.value = this.state.forecast.data[index].value;
      }
    });
    this.setState({
      startDate: startDate,
      endDate: endDate,
      forecast: forecast
    });
    this.props.getSubmittedHOEPforecast({
      startDate: startDate,
      endDate: endDate
    });
  }

  updateForecastData = (points) => {
    const f = this.state.forecast;
    _(points).forEach(point => {
      const p = _.find(f.data, d => d.t === point.t);
      const value = typeof (point[f.forecaster].value) === "string" ?
        Number(point[f.forecaster].value.replace(/[\$,]/g, ''))
        : point[f.forecaster].value;
      p.value = parseFloat(value);
    });
    this.setState({
      forecast: f
    });
  }

  submitForecast() {
    this.props.submitHOEPforecast({
      data: this.state.forecast.data,
      model: this.state.forecast.model
    });
  }

  render() {
    // table html contents
    var table = '';
    // chart html contents
    var chart = '';
    // the full table and chart sections object
    var tableAndChartSections = (<div><FontAwesomeIcon size="2x" icon="spinner" spin /></div>);
    const numDays = this.getForecastPeriod("d");
    const filteredData = FilterDatasets([this.state.forecast], numDays, this.state.startDate);
    filteredData.forEach(f => f.label = f.forecaster !== undefined ? f.forecaster : f.source);
    this.addChartColours(filteredData, chartColours);

    const updateButton = (<UpdateButton textActive="Submit Forecast"
      textDisabled="Submitting..."
      isLoading={this.state.isSending}
      updateFunction={this.submitForecast}
    />);

    table =
      (<PriceForecastTable className="defaultDiv"
      forecasts={filteredData}
        updateDataCallback={this.updateForecastData}
        paginationPageSize={48}
      />);

    chartOptions.annotations = AddDayAnnotations(numDays);
    chart = (<ChartComponent options={chartOptions}
        dataLoaded={!this.state.isSending}
      data={filteredData}
    />);


    tableAndChartSections =
      (<div className="defaultDiv">
        <FlexView column hAlignContent='left' >
          {updateButton}
        </FlexView>
        <br />
        {chart}
        <h2>Data</h2>
        {table}
        <FlexView column hAlignContent='left' >
          {updateButton}
        </FlexView>
        
      </div>
      );
    
    return (
      <div className="defaultDiv">
        <h1>New HOEP Forecast</h1>
        <FlexView vAlignContent='center' hAlignContent='left' height='40px'>
          <FlexView className='labelDiv' column width='180px' hAlignContent='left' >
            <strong>Select Forecast Period:</strong>
          </FlexView>
          <FlexView column hAlignContent='left' >
            <DateRange
              minimumNights={0}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              onDatesChange={this.onDatesChange}
            />
          </FlexView>
        </FlexView>
        {tableAndChartSections}
        <br/>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      forecast: state.forecasts.data.hoep.submitted,
      loading: state.forecasts.data.loading
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(AddHOEPForecast);


const chartOptions = {
  title: "New HOEP Forecast",
  toggleVisibility: true,
  // y axes options
  yLabel: "HOEP ($)",
  yMarginTop: 5,
  yMarginBottom: 5,
  yStepFactor: 5,
  yMajorEnabled: true,
  // x axes options
  xMajorEnabled: true,
  xLabel: "Date",
  xMinorToMajor: 2,
  xNumUnitsToMaxTime: 1,
  xNumUnitsToMinTime: 1,
  xReferenceHourEnabled: false,
  //x.autoSkip : false,
  xTicksMaxRotation: 60,
  xTime: {
    round: false,
    unit: 'hour',
    unitStepSize: 1,
    displayFormats: {
      'hour': 'DD-MM-YYYY kk',
    },
  },
  // zoom/pan options
  zoom: true,
  pan: true,
  panMode: 'xy',
  yStepSizeFunction: function (minValue, maxValue) {
    const yAxisRange = (maxValue - minValue);
    if (yAxisRange < 50)
      return 5;
    else if (yAxisRange < 100)
      return 10;
    else if (yAxisRange < 200)
      return 20;
    else
      return 50;
  },
  xStepSizeFunction: function (min, max, options) {
    const xHourRange = moment(max).diff(min, "h");
    if (xHourRange < 100)
      return 2;
    else if (xHourRange < 200)
      return 4;
    else
      return 16;
  }, 
  annotationUpdateFunctions: [
    UpdateHalfDayAnnotations
  ]
};