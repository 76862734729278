import {
  SET_HISTORICALS_START_DATE,
  REQUEST_HOEP_HISTORICALS,
  RECEIVE_HOEP_HISTORICALS
} from '../actions/Historicals';
import moment from 'moment-timezone';
import _ from 'lodash';
import produce from 'immer';

/// INITIAL STATE
const initialState = {
  // the start date for the historicals
  startDate: moment().startOf("day").add(-1, "d").hour(1),
  // end date
  endDate: moment().startOf("day").hour(24),
  // HISTORICALS state:
  // - HOEP
  data: {
    hoep: {},
    loading: false
  },
  // last time server was contacted for data
  lastUpdatedAt: moment(),
  // SETTINGS state:
  // - empty for now
  settings: {
  }
};

// REDUCER HELPERS


/// REDUCER
export const reducer = produce(
  (draft, action) => {
    switch (action.type) {
      case SET_HISTORICALS_START_DATE:
        draft.startDate = action.payload.startDate;
        draft.endDate = action.payload.endDate;
        break;
      case REQUEST_HOEP_HISTORICALS:
        draft.data.hoep = {};
        draft.data.loading = true;
        break;
      case RECEIVE_HOEP_HISTORICALS:
        action.payload.historicals.forEach(h => {
          h.source = "Market";
          h.data = h.data.map(p => { return { ...p, y: p.value }; });
        });
        draft.data.hoep = action.payload.historicals;
        draft.data.loading = false;
        draft.lastUpdatedAt = moment();
        break;
    }
  },
  initialState
);