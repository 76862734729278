import React from 'react';
import _ from 'lodash';
import 'core-js/es6/array'; // to get just array polyfills for example
import 'core-js/es6/number';
import { GridTable } from './GridTable';
import "ag-grid-community/dist/styles/ag-grid.css";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
  GetColumnDefs, DefaultColumns, ValueFormatters,
  ConvertDataToTableFormat, GetFrameworkComponents
} from './Table/TableColumns';

/**
 * A component for rendering price forecasts table using GridTable comoponent.
 * The component expects forecasts in the props argument, as well as an updateDataCallback
 * function if there is an input feedback between the table and rest of the app
 * @param {any} props the properties object required for rendering the table that includes
 * forecasts array object, and optionally updateDataCallback method reference
 * @returns {Object} A component that renders forecasts table
 */
export const PriceForecastTable = (props) => {
  var forecasts = _.cloneDeep(props.forecasts);
  const tableData =
    ConvertDataToTableFormat(forecasts, "t",
      forecasts.map(f => f.forecaster), "forecaster");
  const columnDefs = [DefaultColumns.DateTimeColumn(),
    ...GetColumnDefs(GetForecastColumnDefs(forecasts.map(f => f.forecaster), props.updateDataCallback))];
  const frameworkComponents = GetFrameworkComponents();

  const newProps = {
    rowData: tableData,
    frameworkComponents: frameworkComponents,
    columnDefs: columnDefs,
    className: props.className,
    paginationPageSize : props.paginationPageSize,
    updateDataCallback: props.updateDataCallback,
    pagination: props.pagination
  };
  return CreateGridViewForTableData(newProps);
};

/**
 * A table component for rendering Metrics table for all forecasts. It expects the props to contain
 * the metrics array of objects.
 * @param {any} props The data containing the metrics that need to be rendered on a table view.
 * @returns {Object} the table view of the metrics
 */
export const MetricTable = (props) => {
  const metrics = props.data;
  const tableData = ConvertDataToTableFormat(metrics, "label",
    metrics.map(m => m.forecaster), "forecaster");
  const columnDefs = [DefaultColumns.MetricCategoryColumn(),
    ...GetColumnDefs(GetForecastMetricColumnDefs(metrics.map(m => m.forecaster)))];
  const frameworkComponents = GetFrameworkComponents();

  const newProps = {
    rowData: tableData,
    frameworkComponents: frameworkComponents,
    columnDefs: columnDefs,
    className: props.className
  };
  return CreateGridViewForTableData(newProps);
};

/**
 * A table component for rendering Averages table for all forecasts. It expects the props to contain
 * the data array of objects.
 * @param {any} props The props containing the data averages that need to be rendered on a table view.
 * @returns {Object} the table view of the metrics
 */
export const AveragesMetricTable  = (props) => {
  const data = props.data;
  const tableData = ConvertDataToTableFormat(data, "category",
    data.map(m => m.forecaster), "forecaster");
  const columnDefs = [DefaultColumns.AveragesCategoryColumn(),
    ...GetColumnDefs(GetAveragesColumnDefs(data.map(m => m.forecaster)))];
  const frameworkComponents = GetFrameworkComponents();

  const newProps = {
    rowData: tableData,
    frameworkComponents: frameworkComponents,
    columnDefs: columnDefs,
    className: props.className
  };
  return CreateGridViewForTableData(newProps);
};

/**
 * A table component for rendering Metrics table for all forecasts. It expects the props to contain
 * the metrics array of objects.
 * @param {any} props The data containing the metrics that need to be rendered on a table view.
 * @returns {Object} the table view of the metrics
 */
export const SubmissionTable = (props) => {
  const submissionDates = props.submissionDates;
  const tableData = ConvertDataToTableFormat(submissionDates, "label",
    submissionDates.map(m => m.forecaster), "forecaster");
  const columnDefs = [DefaultColumns.SubmissionDatesColumn(),
    ...GetColumnDefs(GetSubmissionDatesColumnDefs(submissionDates.map(m => m.forecaster)))];
  const frameworkComponents = GetFrameworkComponents();

  const newProps = {
    rowData: tableData,
    frameworkComponents: frameworkComponents,
    columnDefs: columnDefs,
    className: props.className
  };
  return CreateGridViewForTableData(newProps);
};

/**
 * Helper method for common rendering of a grid view table given props containing
 * all of the arguments needed for GridTable to render
 * @param {any} props all required props for GridTable to render, including rowData, columnDefs
 * @returns {Object} returns a GridTable comopnent wrapped in a div
 */
const CreateGridViewForTableData = (props) => {
  const gridTable =
    (<GridTable
      domLayout='autoHeight'
      {...props}
    />);

  return (<div className={props.className}>
    {gridTable}
  </div>);
}

/**
 * Gets the column definitions for all forecaster(s) in the price forecast table.
 * @param {Array} columnNames The names of the columns i.e forecasters
 * @param {Function} setterCallback If used for submitting forecast, this method used to control input of cells
 * @returns {Array} column definitions in format as expected by GridTable component
 */
function GetForecastColumnDefs(columnNames, setterCallback) {
  var customColumns = columnNames.map(c => {
    return {
      name: c,
      headerName: c,
      subHeaderName: "HOEP",
      field: 'value',
      valueFormatter: ValueFormatters.currency,
      valueSetter: function (params) {
        //TODO - validate if value is number
        params.data[c].value = parseFloat(params.newValue);
        if (setterCallback !== undefined) {
          setterCallback([params.data]);
        }
      }
    };
  });
  return [...customColumns];
}

/**
 * Gets the column definitions for all forecaster(s) for the metric table
 * @param {Array} columnNames The names of the columns i.e forecasters
 * @returns {Array} column definitions in format as expected by GridTable component
 */
function GetForecastMetricColumnDefs(columnNames) {
  var customColumns = columnNames.map(c => {
    return {
      name: c,
      headerName: c,
      field: 'value',
      valueFormatter: ValueFormatters.currency,
      valueSetter: function (params) {
        params.data[c].value = params.newValue;
      }
    };
  });
  return [...customColumns];
}

/**
 * Gets the column definitions for all forecaster(s) for the averages table
 * @param {Array} columnNames The names of the columns i.e forecasters
 * @returns {Array} column definitions in format as expected by GridTable component
 */
function GetAveragesColumnDefs(columnNames) {
  var customColumns = columnNames.map(c => {
    return {
      name: c,
      headerName: c,
      children: [
        {
          headerName: "Peak",
          field: "peak",
          valueFormatter: ValueFormatters.currency,
          valueGetter: (params) => {
            return params.data[c] === undefined || isNaN(params.data[c]["peak"]) ? "" : params.data[c]["peak"];
          }
        },
        {
          headerName: "Off-Peak",
          field: "offpeak",
          valueFormatter: ValueFormatters.currency,
          valueGetter: (params) => {
            return params.data[c] === undefined || isNaN(params.data[c]["offpeak"]) ? "" : params.data[c]["offpeak"];
          }
        }
      ]
    };
  });
  return [...customColumns];
}

/**
 * Gets the column definitions for all forecaster(s) for the submission date table
 * @param {Array} columnNames The names of the columns i.e forecasters
 * @returns {Array} column definitions in format as expected by GridTable component
 */
function GetSubmissionDatesColumnDefs(columnNames) {
  var customColumns = columnNames.map(c => {
    return {
      name: c,
      headerName: c,
      field: 'date',
      valueFormatter: function (params) {
        return params.data[c].date;
      }
    };
  });
  return [...customColumns];
}