import { Component } from 'react';

// Component holding the data logic,
// for displaying data in both chart and table format
export class ChartAndTableView extends Component {
  constructor() {
    super();
    this.state = {
      chartData: [],
      tableData: [],
      tableForecastData: [],
      loading: true
    };

  }

  /**
   * Method updates the passed datasets with the colouring and 
   * styling options as specified in colours object
   * @param {Array} datasets
   * @param {Object} colours 
   */
  addChartColours(datasets, colours){
    datasets.forEach(d => {
      var style = colours[d.label];
      // assign the defaults and then assign specific style
      // if exists
      Object.assign(d, colours["default"]);
      if(style !== undefined) Object.assign(d, style);
    });
  }


  componentDidMount() {
    this.checkPropsForData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // whether generated forecast is still in retrieval phase
    this.checkPropsForData(nextProps);
  }

}
